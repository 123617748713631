import React from 'react';
import style from "./Date.module.css";

const Date = (props) => {
	
	return <div className={style.aboutNews}>
						{props.date} 
				 </div>
}

export default Date;