import React from 'react';
import style from "./Header.module.css";


const Header = (props) => {
	return <div className={style.textHeader}> 
			<h3> {props.head} </h3>
	</div>
}

export default Header;