const dataJSON = {
	"id": 13457,
	"ready": true,
	"name": "News",
	"language": ["ru","ru-RUS"],
	"query": "standard",
	"weight": 100,
	"titleShort": "NaVi – Alliance",
	"titleLong": "Ramzes и Afoninje комментировали матч NAVI – Alliance на стриме Шадовеха",
	"age": [12,999],
	"interaction": [14,2,306],
	"status": 0,
	"category": [0],
	"dateCreate": 1602691784539,
	"dateExpire": 1602712389339,
	"dateHot": 1602691870939,
	"current": {
		"id": 1,
		"name": "cyber.sports.ru",
		"link": "https://cyber.sports.ru/dota2/1091014999-ramzes-i-afoninje-kommentiruyut-match-navi-alliance-na-strime-shadovex.html"
	},
	"content": [
		{
			"type": "container", // container - div
			"name": "MainContainer",// mainWrapper
			"style": "",
			"content": [
				{
					"type": "picture_list", // head-picture
					"name": "Preview",
					"style": "",
					"content": [
						
					]
				},
				{
					"type": "list", // after head
					"name": "News",
					"style": "",
					"content": [
						{
							"type": "main_header",
							"name": "MainHeader",
							"style": "",
							"content": "Ramzes и Afoninje комментировали матч NAVI – Alliance на стриме Шадовеха"
						},
						{
							"type": "date",
							"name": "Date",
							"style": "",
							"content": 16112020
						},
						{
							"type": "link",
							"name": "cyber.sports.ru",
							"style": "",
							"content": "https://cyber.sports.ru/dota2/1091014999-ramzes-i-afoninje-kommentiruyut-match-navi-alliance-na-strime-shadovex.html"
						},
						{
							"type": "text",
							"name": "Text",
							"style": "",
							"content": "Известные игроки Роман «Ramzes» Кушнарев и Андрей «Afoninje» Афонин комментируют игру NAVI – Alliance на стриме Евгения «Sh4dowehhh» Алексеева."
						},
						{
							"type": "text",
							"name": "Text",
							"style": "",
							"content": "Sh4dowehhh стримит Epic League по официальной договоренности с организаторами – с баннерами турнира и кастомным названием."
						},
						{
							"type": "head_link",
							"name": "HeadLink",
							"style": "",
							"content": "5 минут с Рамзесом: как договорился с Сумаилом, миллион на кроссовки и игра по кайфу в Just Error",
							"link":  "https://cyber.sports.ru/tribuna/blogs/itsinthebag/2855109.html"
						},
						{
							"type": "head_link",
							"name": "HeadLink",
							"style": "",
							"content": "Тур для Сумаила по СНГ-Доте: с кем пить, кого слушать, а от кого держаться подальше",
							"link":  "https://cyber.sports.ru/tribuna/blogs/dailyoracle/2854664.html#supertop"
						},
						{
							"type": "video",
							"name": "Video",
							"style": "",
							"content": "https://www.twitch.tv/videos/803932289?t=0h12m6s"
						},
						{
							"type": "text",
							"name": "Text",
							"style": "",
							"content": "Опубликовал Кирилл Новокщенов"
						},
					]
				}
			]
		}
	],
	"user": {
		"interaction": [1,0,1],
		"share": false
	}
}

export default dataJSON;