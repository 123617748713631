import React from 'react';
import './App.css';
import NewsPageContainer from "./components/NewsPage/NewsPageContainer";
import data from "./redux/dataJSON2";


function App() {
  return (
    <div className="App">
       
      <NewsPageContainer data={data} />
        
    </div>
  )
}

export default App;
