import React from 'react';
import NewsPage from './NewsPage';
// import style from "./NewsPageContainer.module.css";

const NEWS_DEFAULT = {
	"id": 0,
	"ready": true,
	"name": "No name",
	"language": ["any"],
	"query": "standard",
	"weight": 0,
	"titleShort": "No title",
	"titleLong": "No title long",
	"age": [0,999],
	"interaction": [0,0,0],
	"status": 0,
	"category": [0],
	"dateCreate": 0,
	"dateExpire": 0,
	"dateHot": 0,
	"current": {},
	"content": [],
	"user": {}
};

const NewsPageContainer = (props) => {
	/**
	 * Default news data
	 */
	const getDefaultNews = () => {
		return NEWS_DEFAULT;
	}
	/**
	 * Method return data if it exist and
	 * return default news if its null
	 */
	const getData = () => {
		return props && props.data ? props.data : getDefaultNews();
	}
	//
	// OBJECTS GENERATE
	//
	/**
	 * Return main news component
	 */
	
	const getMainNews = () => {
		const data = getData();
		return <NewsPage data={data} />
	}
	//why create getMainNews if we have generated obj ??
	

	return <div>
		{ getMainNews() }
	</div>
}

export default NewsPageContainer;