import React from 'react';
import style from "./LinkToNews.module.css";

const LinkToNews = (props) => {

	return <div className={style.link}>

					<a href={props.link} target="_blank" rel="noopener noreferrer">
						 · {props.name}
					</a>

	</div>
}

export default LinkToNews;