import React from 'react';
import PicturePreview from "./PicturePreview/PicturePreview";
import Text from './TextBlockNews/Text/Text';
import Image from './TextBlockNews/Image/Image';
import Video from './TextBlockNews/Video/Video';
import PointText from './TextBlockNews/PointText/PointText';
import Header from './TextBlockNews/Header/Header';
import MainHeader from './TextBlockNews/MainHeader/MainHeader';
import LinkToNews from './TextBlockNews/LinkToNews/LinkToNews';
import Date from './TextBlockNews/Date/Date';
import HeaderLink from './TextBlockNews/HeaderLink/HeaderLink';
import style from "./NewsPage.module.css"



const NewsPage = (props) => {
	// console.log(props)
	const DOM_DEFAULT = <div></div>;

	/**
	 * Return data from props
	 */
	const getData = () => {
		const data = props && props.data ? props.data : null;
		return data
	}

	const getDomContent = () => {
		const data = getData();
		const domContent = data.content;
		return domContent
	}

	//
	const contentBuilder = () => {
		const domContent = getDomContent()
		// const domContent = [
		// 	{
		// 		"type": "container",
		// 		"name": "MainContainer",
		// 		"style": "",
		// 		"content": []
		// 	}
		// ];
		const dom = contentDOMParser(domContent);
		return dom;
	};

	/**
	 * Main content parser
	 * @param { Array } content DOM list with tree content view
	 */
	const contentDOMParser = (content) => {
		if (!Array.isArray(content)) return DOM_DEFAULT;
		const dom = parseNodeEnter(content);
		return dom;
	}
	
	/**
	 * Return complete dom content
	 */
	const getNewsPage = () => {
		const dom = contentBuilder();
		return dom;
	};

	//
	// CONTENT PARSER
	//
	const parseNodeEnter = (content) => {
		const node = createFirstNode(content);
		const dom = parseContainerCreate(node, 0);
		return dom;
	};

	// create node : obj element
	const createFirstNode = (content) => {
		return { content, name: "", type: "container", style: "" };
	};

	// parse content tree nodes
	const parseContent = (viewModel = { node: [], depth: 0 }) => {
		const { node, depth, index } = viewModel;
		const { type, name, style, content } = node;
		switch (type) {
			case "container":
			case "list":
				return parseContainerCreate(node, depth + 1);
			case "picture_list":
				return parsePicturePreviewListCreate(node, index);
			case "main_header":
				return parseMainHeaderCreate(node, index);
			case "date":
				return parseDateCreate(node, index);
			case "link":
				return parseLinkCreate(node, index);
			case "head":
				return parseHeadCreate(node, index);
			case "text":
				return parseTextCreate(node, index);
			case "video":
				return parseVideoCreate(node, index);
			case "point_text":
				return parsePointTextCreate(node, index);
			case "image":
				return parseImageCreate(node, index);
			case "head_link":
				return parseHeaderLinkCreate(node, index);

			default:
				return parseContainerCreateEmpty(node);
		}
	};

	// default case
	const parseContainerCreateEmpty = (node) => {
		console.log("HE СОЗДАН ПАРСЕР ДЛЯ:", node.type, node.name, node.content);
		return <div> </div> ;
	};

	// case "container"-> "list"
	const parseContainerCreate = (node, depth) => {
		const { type, name, style, content } = node;
		const children = content.map((childNode, index) => {
			const viewModel = { node: childNode, depth, index };
			return parseContent(viewModel);
		});
		return (
			<div name={name} key={name}> {children} </div>
		);
	};

	//create elements in case "content" : 
	//picture news create for case "picture-list"
	const parsePicturePreviewListCreate = (node, key) => {
		const { type, name, style, content } = node;
		return (<PicturePreview images={content} name={name} key={key} />);
	};

	//text MainHeader create for case "list"->"head"
	const parseMainHeaderCreate = (node, key) => {
		const { type, name, style, content } = node;
		return (<MainHeader head={content} name={name} key={key} />);
	};
	//text Date create for case "list"->"head"
	const parseDateCreate = (node, key) => {
		const { type, name, style, content } = node;
		return (<Date date={content} name={name} key={key} />);
	};
	//text Link create for case "list"->"head"
	const parseLinkCreate = (node, key) => {
		const { type, name, style, content } = node;
		return (<LinkToNews link={content} name={name} key={key} />);
	};

	//text Header create for case "list"->"head"
	const parseHeadCreate = (node, key) => {
		const { type, name, style, content } = node;
		return (<Header head={content} name={name} key={key} />);
	};

	//text Text create for case "list"->"text"
	const parseTextCreate = (node, key) => {
		const { type, name, style, content } = node;
		return (<Text text={content} name={name} key={key} />);	
	};

	//text Video create for case "list"->"video"
	const parseVideoCreate = (node, key) => {
		const { type, name, style, content } = node;
		return (<Video video={content} name={name} key={key} />);
	};

	//text Video create for case "list"->"head_link"
	const parseHeaderLinkCreate = (node, key) => {
		const { type, name, style, content, link } = node;
		return (<HeaderLink head={content} link={link} name={name} key={key} />);
	};

	//text Point create for case "list"->"point_text"
	const parsePointTextCreate = (node, key) => {
		const { type, name, style, content } = node;
		return (<PointText textPoint={content} name={name} key={key} />);
	};

	//text Image create for case "list"->"image"
	const parseImageCreate = (node, key) => {
		const { type, name, style, content } = node;
		if ( content === "list" ) {
			return null
		}
		return (<Image image={content} name={name} key={key} />);
	};

	// зачем если мы в 119 создаем через div в return
	// const createDivElement = () => {
	// 	return React.createElement("div");
	// }

	return <div className={style.wrapperContent}>
		{ getNewsPage() }
	</div>
}

export default NewsPage;

