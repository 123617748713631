import React from 'react';
import style from "./HeaderLink.module.css";


const Header = (props) => {
	return <div className={style.textHeader}> 
			<h3> <a href={props.link}> {props.head} </a></h3>
	</div>
}


export default Header;