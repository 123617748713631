import React from 'react';
import style from "./PicturePreview.module.css";
import defaultImage from "../../../assets/newspaper.svg";
//bootstrap
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';


const PictureNews = (props) => {
	// get data from props
	const getPicture = () => {
		const images = props && props.images ? props.images : null;
		return images
	}
	const images = getPicture();
	console.log(images)

	return <div className={style.img}>

		<Carousel interval={null} >

			{images.map((image) => 
				 <Carousel.Item key={images.indexOf(image)}>
					{image.type === "image"
						? <img src={image.content} alt="news" className="d-block w-100" />
						: <img src={defaultImage} alt="defaultImage" />}
				</Carousel.Item> 
			)}

		</Carousel>

	</div>
}

export default PictureNews;
