import React from 'react';
import style from "./PointText.module.css";


const PointText = (props) => {
	return <div className={style.textPoint}> 
							 <li> {props.textPoint} </li>
				 </div>
}

export default PointText;