import React from 'react';
import style from "./MainHeader.module.css"

const MainHeader = (props) => {
	return (<div className={style.title}>

							<h1> 
									{props.head ? props.head : "MainHeader News"} 
							</h1>
							
					</div>
)}

export default MainHeader;