import React from 'react';
import style from "./Image.module.css";


const Image = (props) => {
	return <div className={style.blockImg}> 
				<img src={props.image} alt={props.name}/>
			</div>
}

export default Image;