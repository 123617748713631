import React from 'react';
import style from "./Video.module.css";

const Video =(props) => {
	return <div>

		<iframe src={props.video} title={props}frameBorder="0" allowFullScreen={true} scrolling="no" height="378" width="620"></iframe>

	</div>
}

export default Video;
